// 引入 ECharts 主模块
var echarts = require("echarts"); // 引入柱状图


require("echarts/lib/chart/bar"); // 引入提示框和标题组件


require("echarts/lib/component/tooltip");

require("echarts/lib/component/title");

export default {
  name: "echarts",
  props: {
    chartData: {
      type: Object,
      required: true
    },
    echartHeight: {
      type: String,
      default: "300px"
    }
  },

  data() {
    return {
      // chart: null
      imgUrl: ''
    };
  },

  mounted() {
    this.initChart(this.chartData);
  },

  methods: {
    initChart(chartData) {
      let _this = this;

      let myChart = echarts.init(this.$refs.myChart);
      myChart.setOption(chartData); //监听窗口的变化使echarts宽度自适应

      window.addEventListener("resize", () => {
        myChart.resize();
      });
      myChart.on('finished', function () {
        this.imgUrl = myChart.getDataURL();

        _this.$emit('getMessage', this.imgUrl);
      });
    }

  },
  watch: {
    chartData: {
      handler(newValue) {
        // this.chartData = newValue; //把新值赋值给我们的属性数据 
        this.initChart(this.chartData); //刷新echarts图表
      },

      deep: true
    }
  }
};