import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import echart from "@/components/Echars.vue";
import { selecProgressList, selectPlanInfoList, selectPlanInfoById } from '@/api/production';
import programDetailModal from './components/programDetailModal.vue';
const columns = [{
  title: '计划名称',
  dataIndex: 'planName'
}, {
  title: '周期',
  dataIndex: 'time'
}, {
  title: '进度',
  dataIndex: 'progress',
  with: 80,
  slots: {
    customRender: 'progress'
  }
}];
export default defineComponent({
  components: {
    echart,
    programDetailModal
  },

  setup() {
    const mode = ref('1');
    const checked = ref(true);
    const planObject = reactive({
      totalPlanCount: 0,
      finishPlanRate: 0,
      overduePlanCount: 0,
      overduePlanRate: 0,
      totalNodeCount: 0,
      finishNodeRate: 0,
      overdueNodeCount: 0,
      overdueNodeRate: 0
    }); //总节点数

    const totalNodesOption = reactive({
      title: {
        text: "总节点数",
        textStyle: {
          fontSize: 16 // color: "#c4c4c4"

        }
      },
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: ['未启动', '进行中', '延期', '已完成', '延期完成'] // axisLabel: {
        //   textStyle: {
        //     color: "#fff" //坐标值得具体的颜色
        //   }
        // },
        // axisLine: {
        //   lineStyle: {
        //     color: "#FFf"
        //   }
        // },

      },
      yAxis: {
        type: 'value' // axisLabel: {
        //   textStyle: {
        //     color: "#fff" //坐标值得具体的颜色
        //   }
        // },
        // axisLine: {
        //   lineStyle: {
        //     color: "#FFf"
        //   }
        // }

      },
      grid: {
        top: "20%",
        bottom: '15%'
      },
      series: [{
        data: [0, 0, 0, 0, 0, 0, 0],
        type: 'bar',
        itemStyle: {
          color: "#1890ff"
        }
      }]
    });
    const progressOption = reactive({
      tooltip: {
        trigger: 'item'
      },
      series: [{
        // name: 'Access From',
        type: 'pie',
        radius: '50%',
        data: [{
          value: 0,
          name: '0%~20%进度'
        }, {
          value: 0,
          name: '21%~40%进度'
        }, {
          value: 0,
          name: '41%~60%进度'
        }, {
          value: 0,
          name: '61%~80%进度'
        }, {
          value: 0,
          name: '81%~100%进度'
        }],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    });
    const nodeCountOption = reactive({
      title: {
        text: '节点数'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['完成节点数', '延期节点数']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: '完成节点数',
        type: 'line',
        stack: 'Total',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: '延期节点数',
        type: 'line',
        stack: 'Total',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }]
    }); // const dataSumTableData = ref([])

    const spinningOverview = ref(false);

    const getProgressList = async () => {
      const param = {
        isClose: checked.value ? 1 : 0
      };
      spinningOverview.value = true;
      let res = await selecProgressList(param);

      if (res.code === 200) {
        if (res.data.planObject) {
          Object.assign(planObject, res.data.planObject);
        } // dataSumTableData.value = [planObject]


        if (res.data.histogramObject) {
          totalNodesOption.series[0].data = [res.data.histogramObject.notStart, res.data.histogramObject.underway, res.data.histogramObject.overdue, res.data.histogramObject.finished, res.data.histogramObject.overdueFinish];
          progressOption.series[0].data = [{
            value: res.data.histogramObject.zeroTwenty,
            name: '0%~20%进度'
          }, {
            value: res.data.histogramObject.twentyoneForty,
            name: '21%~40%进度'
          }, {
            value: res.data.histogramObject.fortyoneSixty,
            name: '41%~60%进度'
          }, {
            value: res.data.histogramObject.sixtyoneEighty,
            name: '61%~80%进度'
          }, {
            value: res.data.histogramObject.eightyoneHundred,
            name: '81%~100%进度'
          }];
        }

        if (res.data.lineList.length) {
          nodeCountOption.xAxis.data = [];
          nodeCountOption.series[0].data = [];
          nodeCountOption.series[1].data = [];
          res.data.lineList.forEach(item => {
            nodeCountOption.xAxis.data.push(item.yearsMonth);
            nodeCountOption.series[0].data.push(item.finishNodeCount);
            nodeCountOption.series[1].data.push(item.overdueNodeCount);
          });
        }

        spinningOverview.value = false;
      } else {
        spinningOverview.value = false;

        _message.error(res.message);
      }
    };

    const handleSwitch = () => {
      getProgressList();
    };

    const handleTopBtn = () => {
      if (mode.value === '1') {
        getProgressList();
      } else {
        getTableData();
      }
    }; //详情进度


    const tableData = ref([]);
    const nodesOption = reactive({
      title: {
        text: "节点数",
        textStyle: {
          fontSize: 16 // color: "#c4c4c4"

        }
      },
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: ['未启动', '进行中', '延期', '已完成', '延期完成']
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        top: "15%",
        bottom: '8%',
        left: '10%',
        right: '0%'
      },
      series: [{
        data: [0, 0, 0, 0, 0, 0, 0],
        type: 'bar',
        itemStyle: {
          color: "#1890ff"
        }
      }]
    });
    const spinningDetail = ref(false);

    const getTableData = async () => {
      spinningDetail.value = true;
      let res = await selectPlanInfoList();

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];

        if (res.data.length) {
          res.data.forEach(item => {
            arr.push({
              key: item.id,
              planName: item.planName,
              time: `${item.createTime}~${item.planTime}`,
              progress: item.progress,
              isOverdue: item.isOverdue
            });
          });
          getDetailData(res.data[0].id, res.data[0].planName);
          tableData.value = arr;
          tableData.value[0].isSelected = true;
          idDetail.value = tableData.value[0].key;
        }

        spinningDetail.value = false;
      } else {
        spinningDetail.value = false;

        _message.error(res.message);
      }
    };

    const detailTitle = ref('');
    const detailOverview = reactive({
      nodeCount: 0,
      consumeTime: 0,
      planTime: 0,
      residueTime: 0,
      finishNodeRate: 0,
      overdueNodeCount: 0,
      overdueNodeRate: 0,
      cumsumOverdueTime: 0
    });
    const isShowDetail = ref(false);

    const getDetailData = async (id, name) => {
      spinningDetail.value = true;
      const param = {
        id
      };
      let res = await selectPlanInfoById(param);

      if (res.code === 200) {
        detailTitle.value = name;

        if (res.data) {
          isShowDetail.value = true;
          Object.assign(detailOverview, res.data);
          nodesOption.series[0].data = [res.data.notStart, res.data.underway, res.data.overdue, res.data.finished, res.data.overdueFinish];
        } else {
          isShowDetail.value = false;
        }

        spinningDetail.value = false;
      } else {
        spinningDetail.value = false;

        _message.error(res.message);
      }
    };

    const customRow = val => {
      return {
        onClick: event => {
          // console.log(event,val)
          tableData.value.forEach(item => {
            item.isSelected = false;
          });
          val.isSelected = true;
          getDetailData(val.key, val.planName);
          idDetail.value = val.key;
        }
      };
    };

    const rowClassName = record => {
      const defaultClass = ['pointer'];
      record.isSelected && defaultClass.push('row-background');
      return defaultClass.join(' ');
    }; //详情


    const idDetail = ref(0);
    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 0,
      // data:{}
      key: null,
      applyVisible: false,
      isShowApproval: false
    }); //查看详情

    const toView = async () => {
      applyDetailData.state = 0;
      applyDetailData.key = idDetail.value;
      applyDetailData.applyVisible = true;
      applyDetailData.isShowApproval = false;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailData.applyVisible = val.isClose;
    };

    onMounted(() => {
      getProgressList();
    });
    return {
      mode,
      checked,
      ...toRefs(planObject),
      totalNodesOption,
      progressOption,
      nodeCountOption,
      handleSwitch,
      handleTopBtn,
      columns,
      tableData,
      nodesOption,
      spinningDetail,
      spinningOverview,
      detailOverview,
      customRow,
      rowClassName,
      detailTitle,
      isShowDetail,
      applyDetailVisible,
      applyDetailData,
      toView,
      closeApplyDetailVisible
    };
  }

});